import axios from 'axios';
import { getCustomerId } from '../../utils/CustomerId';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// -------------------------------------------------------------------------------------------------------
//                                    fetchrecipients
// -----------------------------------------------------------------------------------------------------
export const recipients = async () => {
  const customerId = getCustomerId();

  if (!customerId) {
    throw new Error('Customer ID is required');
  }
  try {
    // Send a POST request with the customerId using axios
    const response = await axios.post(`${API_BASE_URL}/customer-recipients`,
      { customerId }, // Request body containing customerId
      {
        headers: {
          'Content-Type': 'application/json' // Set content type to JSON
        }
      }
    );

    //   console.log('Customer Data Response:', response);
    return response.data; // Assuming response.data contains the customer details
  } catch (error) {
    console.error('Error fetching customer data:', error);
    throw error;
  }
};
// -------------------------------------------------------------------------------------------------------
//                                    updaterecipients
// -----------------------------------------------------------------------------------------------------
export const updaterecipients = async (recipientData) => {
  const customerId = getCustomerId();

  try {
    const response = await axios.put(
      `${API_BASE_URL}/update-recipient`,
      {
        ...recipientData, // Include recipient data to update
        customerId, // Include customer ID
      },
      {
        headers: {
          'Content-Type': 'application/json', // Set content type to JSON
        },
      }
    );
    return response.data; // Return updated recipient data
  } catch (error) {
    console.error('Error updating recipient data:', error);
    throw error;
  }
};

// -------------------------------------------------------------------------------------------------------
//                                    Deleteecipients
// -----------------------------------------------------------------------------------------------------
export const deleteRecipient = async (id) => {
  const customerId = getCustomerId();


  try {
    const response = await axios.delete(`${API_BASE_URL}/delete-recipient/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data; // Return the response data
  } catch (error) {
    console.error('Error deleting recipient:', error);
    throw error; // Re-throw error for further handling
  }
};



import axios from 'axios';
import { getCustomerId } from '../../utils/CustomerId';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


export const fetchNonSortedOrder =async()=>{
       const customerId = getCustomerId();
  
  try {
    const response = await axios.get(`${API_BASE_URL}/nonsorted-order/${customerId}`);
     console.log("API Response:", response.data); // Log response for debugging
    return response.data;
  } catch (error) {
    // console.error('Error sending order email:', error.response?.data || error.message); // Log detailed error
    throw error;
  }
};

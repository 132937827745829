import React, { useEffect, useState } from "react";
import "./PrescriptionManagement.css";
import { Button, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { cancelOrder, sendOrderEmail } from "../../../../services/HistoryOrder";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const PrescriptionManagement = () => {
    const navigate = useNavigate();
  
  // Data for different sections
    const [newOrders, setNewOrders] = useState([]);
    const [orders, setOrders] = useState([]);
    const [cancelledOrders, setCancelledOrders] = useState([]);
    const [approvedOrders, setApprovedOrders] = useState([]);
    
  
    const otpEmail = useSelector((state) => state.email.email); // Access email state
useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await sendOrderEmail();
        setOrders(response);

        // Filter orders by their status
        setNewOrders(response.filter(order => order.status === 'New'));

        // setCancelledOrders(response.filter(order => order.status === 'cancelled'));
        setApprovedOrders(response.filter(order => order.status === 'Approved'));
      } catch (error) {
        // console.error('Error fetching orders:', error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to fetch orders. Please try again later.",
        });
      }
    };

    fetchOrders();

  }, []);
  // Handle cancel 
    const handleCancelOrder = async (orderId) => {
      try {
        const confirm = await Swal.fire({
          title: "Are you sure?",
          text: "Do you want to cancel this order?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#00909D",
          confirmButtonText: "Yes, cancel it!",
        });
    
        if (confirm.isConfirmed) {
          const response = await cancelOrder(otpEmail, orderId);
          Swal.fire("Cancelled!", "Your order has been cancelled.", "success");
    
          // Update the lists after cancellation
          setNewOrders(newOrders.filter(order => order.id !== orderId));
    
          const updatedOrder = {
            ...response,
            id: orderId,
            updated_at: response.updated_at || new Date().toISOString(), // Handle missing updated_at
            cancelledBy: "Customer",
          };
    
          setCancelledOrders(prev => [...prev, updatedOrder]);
        }
      } catch (error) {
        console.log(error);
        
        if (error.response?.data?.message === "Order is already cancelled.") {
          Swal.fire({
            icon: "info",
            title: "Already Cancelled",
            text: "This order has already been cancelled.",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to cancel the order. Please try again later.",
          });
        }
      }
    };
    
  const pendingOrders = [];
  const inProgressOrders = [];
  const shippedOrders = [];
  const deliveredOrders = [
    {
      id: 1,
      orderNo: "1234",
      placedOn: "01-01-2024 11:15PM",
      for: "Myself",
      address: "House No. 1234",
    },
  ];

  const renderTableRows = (orders, hasAction = false, actionLabel = "") => {
    if (orders.length === 0) {
      return (
        <tr>
          <td colSpan={hasAction ? 6 : 5} className="text-center">
            No record
          </td>
        </tr>
      );
    }
  }


  return (
    <Container>
             <div className="mt-5">
             <h1 className='text-center mainhead fw-bold '>Prescription Management</h1>
      <div className="text-end pt-2">
        <button onClick={()=>{navigate('/preSorted-order')}} className="btn btn-primary placeNewbtn ">Place New</button>
      </div>

      {/* New Section */}
      <div className="section mb-4 pt-1">
        <h5 className="fw-bold">New</h5>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead className="">
              <tr>
                <th>S#</th>
                <th className="text-nowrap">Order No.</th>
                <th>Placed On</th>
                <th>For</th>
                <th>Address</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
                         {newOrders.length > 0 ? (
                           newOrders.map((order, index) => (
                             <tr key={order.id}>
                               <td>{index + 1}</td>
                               <td>{order.id}</td>
                               <td className="text-nowrap">{order.created_at}</td>
                               <td>
  {order.orderingFor === "myself"
    ? `${order.orderingFor}`
    : `${order.relationToPatient}`}
</td>

                               <td>{order.address}</td>
                               <td>
                                 <Button
                                   className="cnclpresbtn"
                                   onClick={() => handleCancelOrder(order.id)}
                                 >
                                   Cancel Order
                                 </Button>
                               </td>
                             </tr>
                           ))
                         ) : (
                           <tr>
                             <td colSpan="5" className="text-center">
                               No New orders
                             </td>
                           </tr>
                         )}
                       </tbody>
          </table>
        </div>
      </div>

      {/* Pending Section */}
      <div className="section mb-4">
        <h5 className="fw-bold">Approved</h5>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
              <th>S#</th>
                <th>Order No.</th>
                <th>Placed On</th>
                <th>For</th>
                <th>Address</th>
               
              </tr>
            </thead>
            <tbody> {approvedOrders.length > 0 ? (
                approvedOrders.map((order, index) => (
                  <tr key={order.id}>
                    <td>{index + 1}</td>
                    <td>{order.id}</td>
                    <td>{order.created_at}</td>
                    <td>
  {order.orderingFor === "myself"
    ? `${order.orderingFor}`
    : `${order.relationToPatient}`}
</td>

                               <td>{order.address}</td>
                               
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center">
                    No Approved orders
                  </td>
                </tr>
              )}</tbody>
          </table>
        </div>
      </div>

      {/* In Progress Section */}
      <div className="section mb-4">
        <h5 className="fw-bold">In Progress</h5>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>S#</th>
                <th>Order No.</th>
                <th>Placed On</th>
                <th>For</th>
                <th>Address</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>{renderTableRows(inProgressOrders)}</tbody>
          </table>
        </div>
      </div>

      {/* Shipped Section */}
      <div className="section mb-4">
        <h5 className="fw-bold">Shipped</h5>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>S#</th>
                <th>Order No.</th>
                <th>Placed On</th>
                <th>For</th>
                <th>Address</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>{renderTableRows(shippedOrders)}</tbody>
          </table>
        </div>
      </div>

      {/* Delivered Section */}
      <div className="section mb-4">
        <h5 className="fw-bold">Delivered</h5>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>S#</th>
                <th>Order No.</th>
                <th>Placed On</th>
                <th>For</th>
                <th>Address</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{renderTableRows(deliveredOrders, true, "Reorder")}</tbody>
          </table>
        </div>
      </div>
    </div>
    </Container>
   
  );
};

export default PrescriptionManagement;



export default{
 Calender:require('./calender.png'), 
 Rightarrow:require('./rightarrow.png'),
 Dummy:require('./dummy.png'),  
 Autmomatic:require('./Automatic.png'),
clock:require('./clock.png'),
everything:require('./Everything.png'),
mobile:require('./mobile.png'),
DeliveryTruck:require('./DeliveryTruck.png'),
Banner:require('./banner1.png'),
Prescription:require('./Prescription.jpg'),
Conditions:require('./T&C.jpg'),
Privacy:require('./Privacy.png'),
cancel:require('./Cancel.jpg'),
Delivery:require('./Delivery.jpg'),
Home:require('./Home.jpg'),
orderfooter:require('./confirmfooter.png'),
orderheader:require('./confrimHeader.png'),
HomeVideo:require('./HomeVideo.mp4'),
panadol:require('./Panadol.png'),
capsule:require('./capsule.png'),
box:require('./box.png'),
box_w:require('./box.jpg'),
medicated:require('./medicated.png'),
presorted:require('./sorted.png'),
nonsorted:require('./non-sorted.png'),
deliverybike:require('./deliverybike.png'),
NonSortedBg:require("./NonSortedBg.jpg"),
easypaisa:require("./easypaisa.png"),
jazzcash:require('./JazzCash.png'),
thankyou:require('./thankyou.png'),
PrivacyPolicy:require('./PrivacyPolicy.jpg')
}
import React, { useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './Default.css';
import {getDashboardData } from '../../../../../services/CustomerDashboard/DashboardCount';
import { useState } from 'react';
const Default = () => {
  const[pending,setPending]=useState("")
  const[neworder,setNewOrder]=useState("")
  const[progress,setProgress]=useState("")
 const[shipped,setShipped]=useState("")
 const [Delivered,setDelivered]=useState("")
  // states for non sorted order 
  const[NewnonSorted,setNewNonSorted]=useState("")
 const[Processing,setProcessing]=useState("")
 const[NonSortedShipped,setNonSortedShipped]=useState("")
 const[NonSortedDelivered,setNonSortedDelivered]=useState("")
  useEffect(() => {
    // Function to fetch and log data
    const fetchData = async () => {
      try {
        const data = await getDashboardData(); // Call the API function
        console.log('API Response:', data.presorted.New); // Log the response data
        console.log("NonSorted:",data.nonsorted);
        
        // Update state only if data exists; otherwise, fallback to 0
        setNewOrder(data.presorted?.New || 0);
        setProgress(data.presorted?.InProgress || 0);
        setShipped(data.presorted?.Shipped || 0);
        setDelivered(data.presorted?.Delivered || 0);
        setPending(data.presorted?.Pending || 0);
      //  -----------------NON SORTED---------------
      setProcessing(data.nonsorted?.Processing || 0);
      setNewNonSorted(data.nonsorted?.New||0)
      setNonSortedShipped(data.nonsorted?.Shipped || 0);
      setNonSortedDelivered(data.nonsorted?.Delivered || 0);
      setPending()
      } catch (error) {
        console.error('Error fetching customer data:', error.message); // Log any errors
      }
    };

    fetchData(); // Execute the fetch function
  }, []); // Empty dependency array ensures this runs once when the component mounts

  return (



    <Container className="mt-5">
      <h1 className="text-center mainhead fw-bold">Dashboard</h1>
   
       
<Row className="pt-5 mt-md-2 d-flex">
<div className='d-flex justify-content-center justify-content-md-start'>
          <Button variant="primary" type="submit" className='nonsortedbtn fw-bolder fs-5 ms-4  '>
            Non-Sorted
          </Button>
        </div>
</Row>
      <Row className='d-flex justify-content-start align-items-center'>
      <Col className=" mt-4 col-md-3 col-12">
          <Card className="status-card text-center">
            <Card.Body className='d-flex flex-column justify-content-between'>
              <div className='d-flex flex-row justify-content-between p-2'>
                <h2 className="status-number fs-1 fw-bolder">{NewnonSorted}</h2>
                <i className="bi bi-bell status-icon fs-1"></i>
              </div>
              <div className='d-flex justify-content-start ms-1'>
                <p className="status-text fs-4  fw-bolder mt-auto">New</p>
              </div>
            </Card.Body>
          </Card>
        </Col>
        {/* Processing Card */}
        <Col className=" mt-4 col-md-3 col-12 ">
          <Card className="status-card text-center">
            <Card.Body className='d-flex flex-column justify-content-between'>
              <div className='d-flex flex-row justify-content-between p-2'>
                <h2 className="status-number fs-1 fw-bolder">{Processing}</h2>
                <i className="bi bi-arrow-repeat status-icon fs-1"></i>
              </div>
              <div className='d-flex justify-content-start ms-1'>
                <p className="status-text fs-4  fw-bolder mt-auto">Processing</p>

              </div>
            </Card.Body>
          </Card>
        </Col>

        {/* Shipped Card */}
        <Col className=" mt-4 col-md-3 col-12">
          <Card className="status-card text-center">
            <Card.Body className='d-flex flex-column justify-content-between'>
              <div className='d-flex flex-row justify-content-between p-2'>
                <h2 className="status-number fs-1 fw-bolder">{NonSortedShipped}</h2>
                <i className="bi bi-truck status-icon fs-1"></i>

              </div>
              <div className='d-flex justify-content-start ms-1'>
                <p className="status-text fs-4  fw-bolder mt-auto">Shipped</p>
              </div>
            </Card.Body>
          </Card>
        </Col>

        {/* Delivered Card */}
        <Col className=" mt-4 col-md-3 col-12">
          <Card className="status-card text-center">
            <Card.Body className='d-flex flex-column justify-content-between'>
              <div className='d-flex flex-row justify-content-between p-2'>
                <h2 className="status-number fs-1 fw-bolder">{NonSortedDelivered}</h2>
                <i className="bi bi-box-seam status-icon fs-1"></i>

              </div>
              <div className='d-flex justify-content-start ms-1'>
                <p className="status-text fs-4  fw-bolder mt-auto">Delivered</p>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row >
      <div className='d-flex justify-content-center justify-content-md-start mt-4'>
          <Button variant="primary" type="submit" className='nonsortedbtn fw-bolder fs-5 ms-4  '>
            Pre-Sorted
          </Button>
        </div>
      </Row>
      <Row className="mt-4 mt-md-1 d-flex justify-content-between  ">
       

        {/* Processing Card */}
        <Col className=" mt-4 col-md-3 col-12">
          <Card className="status-card text-center">
            <Card.Body className='d-flex flex-column justify-content-between'>
              <div className='d-flex flex-row justify-content-between p-2'>
                <h2 className="status-number fs-1 fw-bolder">{neworder}</h2>
                <i className="bi bi-bell status-icon fs-1"></i>
              </div>
              <div className='d-flex justify-content-start ms-1'>
                <p className="status-text fs-4  fw-bolder mt-auto">New</p>

              </div>
            </Card.Body>
          </Card>
        </Col>

        {/* Shipped Card */}
        <Col className=" mt-4 col-md-3 col-12">
          <Card className="status-card text-center">
            <Card.Body className='d-flex flex-column justify-content-between'>
              <div className='d-flex flex-row justify-content-between p-2'>
                <h2 className="status-number fs-1 fw-bolder">{pending}</h2>
                <i className="bi bi-hourglass-split status-icon fs-1"></i>

              </div>
              <div className='d-flex justify-content-start ms-1'>
                <p className="status-text fs-4  fw-bolder mt-auto">Pending</p>
              </div>
            </Card.Body>
          </Card>
        </Col>

        {/* Delivered Card */}
        <Col className=" mt-4 col-md-3 col-12">
          <Card className="status-card text-center">
            <Card.Body className='d-flex flex-column justify-content-between'>
              <div className='d-flex flex-row justify-content-between p-2'>
                <h2 className="status-number fs-1 fw-bolder">{progress}</h2>
                <i className="bi bi-box-seam status-icon fs-1"></i>

              </div>
              <div className='d-flex justify-content-start ms-1'>
                <p className="status-text fs-4  fw-bolder mt-auto">In Progress</p>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col className=" mt-4 col-md-3 col-12">
          <Card className="status-card text-center">
            <Card.Body className='d-flex flex-column justify-content-between'>
              <div className='d-flex flex-row justify-content-between p-2'>
                <h2 className="status-number fs-1 fw-bolder">{shipped}</h2>
                <i className="bi bi-truck status-icon fs-1"></i>

              </div>
              <div className='d-flex justify-content-start ms-1'>
                <p className="status-text fs-4  fw-bolder mt-auto">Shipped</p>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col className=" mt-4 col-md-3 col-12 me-3">
          <Card className="status-card text-center">
            <Card.Body className='d-flex flex-column justify-content-between'>
              <div className='d-flex flex-row justify-content-between p-2'>
                <h2 className="status-number fs-1 fw-bolder">{Delivered}</h2>
                <i className="bi bi-box-seam status-icon fs-1"></i>

              </div>
              <div className='d-flex justify-content-start ms-1'>
                <p className="status-text fs-4  fw-bolder mt-auto">Delivered</p>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Default;

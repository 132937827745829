import React, { useState, useRef, useEffect } from 'react';
import { Button, Container, Form, Alert, Row } from 'react-bootstrap';
import CustomNavbar from '../../Components/Navbar/CustomNavbar'
import Footer from '../../Components/Footer';
import './PrescriptionOrder.css';
import { submitOrderForm } from '../../services/PreSortedOrder';
import Swal from 'sweetalert2';
import Images from '../../assets/Images';
import { Dropzone } from '../../Components/Dropzone';
import { useNavigate } from 'react-router-dom';
import { sendOrderEmail } from '../../services/HistoryOrder';
import ThankYouScreen from '../ThankYouScreen/ThankYouScreen';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '../../Components/Spinner/LoadingSpinner';
import { getCustomerData } from '../../services/CustomerDashboard/profileManagement';
import { setProfileData } from '../../features/profile/profileSlice';

export default function PrescriptionOrder() {
  const [files, setFiles] = useState([]);
  // const otpEmail = localStorage.getItem("otpEmail");
  const otpEmail = useSelector((state) => state.email.email); // Access email state
  const [submitbtn, setsubmitbtn] = useState(true); //for disable or manaage submit button
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  const thankyou = useRef();
  const prescription = useRef();
  const navigate = useNavigate();
  const [isForSomeoneElse, setIsForSomeoneElse] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    address: '',
    durationType: '',
    durationNumber: '',
    orderingFor: 'myself', // Default option
    patientName: '',
    relationToPatient: '',
    images: [],
    state: '',
    city: '',
    nonPrescriptionMedicine: '',
    specialInstructions: '',
  });

  const [error, setError] = useState('');
  const [errors, setErrors] = useState({});
  const fileInputRef = useRef(null);
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const phoneRef = useRef(null);
  const emailRef = useRef(null);
  const addressRef = useRef(null);
  const imagesRef = useRef(null);
  // Scroll to form on component load
  useEffect(() => {
    if (prescription.current) {
      prescription.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);


  //  check for customer name in from backend
  useEffect(() => {
    const fetchdata = async () => {
      try {
        const response = await getCustomerData();
        // console.log(response);
        dispatch(setProfileData({ lastName: response.data.lastName }))
      }
      catch (error) {
        console.error("Error fetching customer data:", error);
      }
    }
    fetchdata();
  }, [dispatch])


  const handleFileUpload = (files) => {
    setFormData((prev) => ({ ...prev, images: files }));
  };
  const handleOrderingForChange = (e) => {
    const value = e.target.value;
    setFormData((prev) => ({ ...prev, orderingFor: value }));

    // Toggle `isForSomeoneElse` based on the selection
    setIsForSomeoneElse(value === 'someoneElse');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {

    e.preventDefault();
    let validationErrors = {};
    let firstErrorRef = null;
    if (!formData.firstName.trim()) {
      validationErrors.firstName = 'First name is required.';
      firstErrorRef = firstErrorRef || firstNameRef;
    }
    if (!formData.lastName.trim()) {
      validationErrors.lastName = 'Last name is required.';
      firstErrorRef = firstErrorRef || lastNameRef;
    }
    if (!formData.phone.trim()) {
      validationErrors.phone = 'Phone number is required.';
      firstErrorRef = firstErrorRef || phoneRef;
    }
    // if (isForSomeoneElse) {
    //   if (!formData.patientName.trim()) {
    //     validationErrors.patientName = 'Patient name is required.';
    //     firstErrorRef = firstErrorRef || patientNameRef;
    //   }
    //   if (!formData.relationToPatient.trim()) {
    //     validationErrors.relationToPatient = 'Relation to patient is required.';
    //     firstErrorRef = firstErrorRef || relationToPatientRef;
    //   }
    // }

    // if (!formData.email.trim() || !/\S+@\S+\.\S+/.test(formData.email)) {
    //   validationErrors.email = 'A valid email is required.';
    //   firstErrorRef = firstErrorRef || emailRef;
    // }
    if (!formData.address.trim()) {
      validationErrors.address = 'Address is required.';
      firstErrorRef = firstErrorRef || addressRef;
    }
    if (!formData.images || formData.images.length === 0) {
      validationErrors.images = 'Please upload an image of your prescription.';
      firstErrorRef = firstErrorRef || imagesRef;
    }
    if (!formData.state.trim()) validationErrors.state = 'State is required.';
    if (!formData.city.trim()) validationErrors.city = 'City is required.';
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      if (firstErrorRef && firstErrorRef.current) {
        firstErrorRef.current.scrollIntoView({ behavior: 'smooth' });
        firstErrorRef.current.focus();
      }
      return;
    }
    setErrors({});
    try {
      const confirmOrder = await Swal.fire({
        title: "Are you sure?",
        text: "Do you want to place this order?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00909D",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, place order!"
      });
      if (confirmOrder.isConfirmed) {
        setsubmitbtn(false)
        setLoader(true)
        const finalFormData = { ...formData, email: otpEmail };
        // Log the data before sending
        console.log("Data being sent to backend:", finalFormData);
        const response = await submitOrderForm(finalFormData);
        console.log('Order successfully submitted:', response.orderNumber);
        const orderNumber = (response.orderNumber)
        localStorage.setItem("orderNumber", orderNumber)
        setFiles([]);
        Swal.fire({
          title: "Order Submitted Successfully!",
          text: "You will be notified shortly.",
          icon: "success",
          confirmButtonColor: "#00909D",
        }).then((result) => {
          if (result.isConfirmed) {
            setsubmitbtn(true)
            setLoader(false)
            thankyou.current.className = "d-block"
            prescription.current.className = "d-none"
            // Navigate to the desired route after confirmation
            // navigate('/thankyou'); // Replace with your desired route
          }
        });
        // Reset the form after successful submission
        setFormData({
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
          address: '',
          durationType: '',
          durationNumber: '',
          orderingFor: '',
          patientName: '',
          relationToPatient: '',
          images: [],
          state: '',
          city: '',
          nonPrescriptionMedicine: '',
          specialInstructions: '',
        });

        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      }
    } catch (error) {
      console.error('Error during form submission:', error);
      setsubmitbtn(true)
      setLoader(false)
      const errorMessage = error.response?.data?.message ||
        error.response?.data?.errors?.[0] || // If there's an array of errors
        error.message;
      Swal.fire({
        icon: "error",
        title: "Submission Failed",
        text: errorMessage,
      });
    }
  };
  // const handleViewPreviousOrders = async () => {
  //   try {
  //     const response = await sendOrderEmail(otpEmail);
  //     console.log("Orders fetched successfully:", response);
  //     navigation('/history', { state: { orders: response } }); // Pass orders data to OrderHistory
  //   } catch (error) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Failed to Fetch Orders",
  //       text: "Could not fetch orders. Please try again later.",
  //     });
  //   }
  // };
  return (
    <>
      <CustomNavbar />
      <div className='row overflow-hidden'>
        <img className='' src={Images.NonSortedBg} alt='logo' />
      </div>
      {/* <div className='row mt-2 '>
        <div className='col-md-11 d-flex justify-content-end'>
          <Button className='historybtn' onClick={() => navigate('/history')}>View Previous Orders</Button>
        </div>
      </div> */}
      {/* --------------------------------Form---------------------- */}
      <Container className="mt-5" id='prescription' ref={prescription}>
        <h6 id='error' style={{ color: "#00909D" }}>Please Enter Your Details</h6>

        {error && <Alert variant="danger">{error}</Alert>}
        <Form encType='multipart/form-data' onSubmit={handleSubmit}>
          <div className='row'>
            <Form.Group className="mb-3 col-md-6" controlId="formFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                ref={firstNameRef}
                className={`${errors.firstName ? 'is-invalid' : ''} inputs`}
              />
              {errors.firstName && <div className="invalid-feedback">{errors.firstName}</div>}
            </Form.Group>
            <Form.Group className="mb-3 col-md-6" controlId="formLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                ref={lastNameRef}
                className={`${errors.lastName ? 'is-invalid' : ''} inputs`}
              />
              {errors.lastName && <div className="invalid-feedback">{errors.lastName}</div>}
            </Form.Group>
          </div>
          <div className='row'>
            <Form.Group className="mb-3 col-md-6" controlId="formPhone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                ref={phoneRef}
                className={`${errors.phone ? 'is-invalid' : ''} inputs`}
              />
              {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
            </Form.Group>
            <Form.Group className="mb-3 col-md-6" controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                ref={emailRef}
                className={`${errors.email ? 'is-invalid' : ''} inputs`}
              />
              {errors.email && <div className="invalid-feedback">{errors.email}</div>}
            </Form.Group>
          </div>
          <div className='row'>
            <Form.Group className="mb-3 col-md-6" controlId="formAddress">
              <Form.Label>Delivery Address</Form.Label>
              <Form.Control
                as="textarea"
                name="address"
                value={formData.address}
                onChange={handleChange}
                ref={addressRef}
                rows={2}
                className={`${errors.address ? 'is-invalid' : ''}inputs`}
              />
              {errors.address && <div className="invalid-feedback">{errors.address}</div>}
            </Form.Group>

            <Form.Group className=" col-md-3" controlId="formAddress">
              <Form.Label>City</Form.Label>
              <Form.Control

                name="city"
                value={formData.city}
                onChange={handleChange}
                isInvalid={!!errors.city}
                ref={addressRef}
                rows={2}
                style={{
                  border: '1px solid grey',
                  borderRadius: '4px',

                }}
              />
              <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className=" col-md-3" controlId="formAddress">
              <Form.Label>State</Form.Label>
              <Form.Control

                name="state"
                value={formData.state}
                onChange={handleChange}
                isInvalid={!!errors.state}
                ref={addressRef}
                rows={2}
                style={{
                  border: '1px solid grey',
                  borderRadius: '4px',

                }}
              />
              <Form.Control.Feedback type="invalid">{errors.state}</Form.Control.Feedback>
            </Form.Group>
          </div>

          <div className='row'>
            <Form.Group className="mb-3 col-md-6">
              <Form.Label>Order Duration</Form.Label>
              <Form.Select
                name="durationType"
                value={formData.durationType}
                onChange={handleChange}
                required
              >
                <option value="" disabled>Select Duration</option>
                <option value="Days">Days</option>
                <option value="Weeks">Weeks</option>
                <option value="Months">Months</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3 col-md-6">
              <Form.Label>Number of Duration</Form.Label>
              <Form.Select
                name="durationNumber"
                value={formData.durationNumber}
                onChange={handleChange}
                required
              >
                <option value="" disabled>Select Number</option>
                {[...Array(31).keys()].map(num => (
                  <option key={num + 1} value={num + 1}>
                    {num + 1}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </div>
          <div className='row'>
            {/* Ordering Medication For */}
            <Form.Group className="mb-3 col-md-12">
              <Form.Label>Ordering Medication For</Form.Label>
              <Form.Select
                aria-label="Select ordering option"
                value={formData.orderingFor}
                onChange={handleOrderingForChange}
              >
                <option value="myself">Myself</option>
                <option value="someoneElse">Someone Else</option>
              </Form.Select>
            </Form.Group>

            {/* Conditional fields if "Someone Else" is selected */}
            {isForSomeoneElse && (
              <>
                {/* Patient's Name */}
                <Form.Group className="mb-3 col-md-6">
                  <Form.Label htmlFor="patientName">Patient's Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="patientName"
                    placeholder="Enter patient's name"
                    required
                    name="patientName" // Add name attribute
                    value={formData.patientName} // Bind value to state
                    onChange={handleChange} // Update state on change
                    className='inputs'
                  />
                </Form.Group>

                {/* Relation to Patient */}
                <Form.Group className="mb-3  col-md-6">
                  <Form.Label htmlFor="relationToPatient">Relation to Patient</Form.Label>
                  <Form.Select id="relationToPatient"
                    aria-label="Select relation"
                    name="relationToPatient" // Add name attribute
                    value={formData.relationToPatient} // Bind value to state
                    onChange={handleChange} // Update state on change
                    required>
                    <option value="">Select Relation</option>
                    <option value="parent">Parent</option>
                    <option value="spouse">Spouse</option>
                    <option value="child">Child</option>
                    <option value="friend">Friend</option>
                    <option value="other">Other</option>
                  </Form.Select>
                </Form.Group>
              </>
            )}
          </div>
          <Form.Group className="mb-3">
            <Form.Label>Upload Prescription</Form.Label>
            <Dropzone onFileUpload={handleFileUpload} files={files} setFiles={setFiles} />
            {errors.images && <div className="text-danger">{errors.images}</div>}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Non-Prescription Medicine</Form.Label>
            <Form.Control
              as="textarea"
              name="nonPrescriptionMedicine"
              value={formData.nonPrescriptionMedicine}
              onChange={handleChange}
              className='inputs'

              rows={3}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Special Instructions</Form.Label>
            <Form.Control
              as="textarea"
              name="specialInstructions"
              value={formData.specialInstructions}
              onChange={handleChange}
              rows={3}
              className='inputs'
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              label="I agree to receive refill reminders and subscribe to the Smart Medics newsletter for updates and offers."
            />
          </Form.Group>
          {loader && (<>
            <LoadingSpinner />
          </>)}
          <Button disabled={!submitbtn} type="submit" style={{ backgroundColor: "#00909D" }} className="w-100">
            Submit
          </Button>
        </Form>
      </Container>
      {/* --------------------------------------------Thankyou Screen---------------- */}
      <div className='d-none' ref={thankyou}>
        <ThankYouScreen />
      </div>
      <Footer />
    </>
  );
}

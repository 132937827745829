import axios from 'axios';
import { getCustomerId } from '../../utils/CustomerId';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// -------------------------------------------------------------------------------------------------------
//                                    fetchrAddress
// -----------------------------------------------------------------------------------------------------

export const address = async () => {
  const customerId = getCustomerId();
  // console.log(customerId);
  try {
    // Send a POST request with the customerId using axios
    const response = await axios.post(`${API_BASE_URL}/customer-address-book`,
      { customerId }, // Request body containing customerId
      {
        headers: {
          'Content-Type': 'application/json' // Set content type to JSON
        }
      }
    );

    //   console.log('Customer Data Response:', response);
    return response.data; // Assuming response.data contains the customer details
  } catch (error) {
    console.error('Error fetching customer data:', error);
    throw error;
  }
};

// -------------------------------------------------------------------------------------------------------
//                                    UpadateAddress
// -----------------------------------------------------------------------------------------------------
export const updateAdress = async (updatedData) => {
  const customerId = getCustomerId();


  try {
    const response = await axios.put(
      `${API_BASE_URL}/update-address`,
      {
        ...updatedData, // Include recipient data to update
        customerId, // Include customer ID
      },
      {
        headers: {
          'Content-Type': 'application/json', // Set content type to JSON
        },
      }
    );
    return response.data; // Return updated recipient data
  } catch (error) {
    console.error('Error updating Address data:', error);
    throw error;
  }
};

// -------------------------------------------------------------------------------------------------------
//                                   DeleteAddress
// -----------------------------------------------------------------------------------------------------
export const deleteAdress = async (id) => {
  if (!id) {
    throw new Error('Address ID is required');
  }

  try {
    const response = await axios.delete(`${API_BASE_URL}/delete-address/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data; // Return the response data
  } catch (error) {
    console.error('Error deleting Address:', error);
    throw error; // Re-throw error for further handling
  }
};
import axios from 'axios';
import { getCustomerId } from '../../utils/CustomerId';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


export const emailNotifications = async (emailEnabled) => {
     const customerId = getCustomerId();
   

    const payload = {
        customer_id: customerId,
        email_notif: emailEnabled, // Match the database variable name
    };

    try {
        const response = await axios.post(`${API_BASE_URL}/notifications/email/toggle`, payload, {
            headers: {
                'Content-Type': 'application/json', // Use JSON for simplicity
            },
        });
        console.log('Email notification toggle updated:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error updating email notifications:', error);
        throw error;
    }
};
export const smsNotifications = async (smsEnabled) => {
    const customerId = getCustomerId();
    const payload = {
        customer_id: customerId,
        sms_notif: smsEnabled, // Match the database variable name
    };

    try {
        const response = await axios.post(`${API_BASE_URL}/notifications/sms/toggle`, payload, {
            headers: {
                'Content-Type': 'application/json', // Use JSON for simplicity
            },
        });
        console.log('Email notification toggle updated:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error updating sms notifications:', error);
        throw error;
    }
};

// function to call toggle behaviour from backend 

export const NotificationToggle = async () => {
    const customerId = getCustomerId();

    try {
        const response = await axios.get(`${API_BASE_URL}/notifications/settings/${customerId}`);
        console.log('API Response:', response.data);
        return response.data;
    } catch (error) {
        if (error.response) {
            // Server responded with a status code outside the range of 2xx
            console.error('Error Response:', error.response.data);
            console.error('Status Code:', error.response.status);
        } else if (error.request) {
            // Request was made, but no response received
            console.error('No Response:', error.request);
        } else {
            // Other errors
            console.error('Error Message:', error.message);
        }
        throw error; // Rethrow the error for further handling
    }
};


//function to call notifications 
export const fetchNotificaitions = async (page, limit) => {
    const customerId = getCustomerId();


    try {
        const response = await axios.get(`${API_BASE_URL}/notifications/${customerId}`,{
            params: {
                page, // Current page number
                limit, // Number of items per page
            },
        });
        console.log('API Response:', response.data);
        return response.data;
    } catch (error) {
        if (error.response) {
            // Server responded with a status code outside the range of 2xx
            console.error('Error Response:', error.response.data);
            console.error('Status Code:', error.response.status);
        } else if (error.request) {
            // Request was made, but no response received
            console.error('No Response:', error.request);
        } else {
            // Other errors
            console.error('Error Message:', error.message);
        }
        throw error; // Rethrow the error for further handling
    }
};
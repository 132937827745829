import axios from 'axios';
import { getCustomerId } from '../../utils/CustomerId';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const UserProfile = async (formData) => {
  const formDataObj = new FormData();
      const customerId = getCustomerId();
 

  // Append session storage data
  formDataObj.append('customerId', customerId);

  // Append form fields
  for (const [key, value] of Object.entries(formData)) {
    formDataObj.append(key, value);
  }

  try {
    const response = await axios.post(`${API_BASE_URL}/profile-store`, formDataObj, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log('UserProfile Response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error submitting profile:', error);
    throw error;
  }
};

// Function to fetch customer data based on customerId

// Function to fetch customer data based on customerId
export const getCustomerData = async () => {
    const customerId = sessionStorage.getItem('customerId');
  
    if (!customerId) {
      throw new Error('Customer ID is required');
    }
  
    try {
      // Send a POST request with the customerId using axios
      const response = await axios.post(`${API_BASE_URL}/customer-profile`, 
        { customerId }, // Request body containing customerId
        {
          headers: {
            'Content-Type': 'application/json' // Set content type to JSON
          }
        }
      );
      
    //   console.log('Customer Data Response:', response);
      return response.data; // Assuming response.data contains the customer details
    } catch (error) {
      console.error('Error fetching customer data:', error);
      throw error;
    }
  };
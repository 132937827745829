import React, { useEffect, useState } from "react";
import { Table, Button, Form, Container } from "react-bootstrap";
import { deleteRecipient, recipients, updaterecipients } from "../../../../services/CustomerDashboard/receiptProfile";
import Swal from "sweetalert2";
import LoadingSpinner from "../../../../Components/Spinner/LoadingSpinner";

function RecipientProfile() {
    const [loader,setLoader]=useState(true);
  
     const [addresses, setAddresses] = useState([
        {
            id: "",
            customerId:"",
            fullName: "",
            relationship: "",
            address: "",
            phoneNumber: "",
            isEditing: false,
        },
      ]);
    
    
    
      useEffect(() => {
        const fetchRecipients = async () => {
          try {
            const response = await recipients();
            // Log the response
            console.log(response.data);
            if (Array.isArray(response.data)) {
              const processedData = response.data.map((item, index) => ({
                id: item.id, // Assign an ID if not already available
                customerId:item.customer_id,
                fullName: item.name || "Unknown", // Replace `fullName` with the actual key from your API
                relationship: item.relationToPatient || "N/A", // Replace `relationship` with the actual key
                address: item.address || "No Address", // Replace `address` with the actual key
                phoneNumber: item.phone || "No Phone Number", // Replace `phoneNumber` with the actual key
                isEditing: false, // Default value for your use case
              }));
              setLoader(false)           
      
              // Set the processed data in the state
              setAddresses(processedData);
            } 
          } catch (err) {
            // setError('Failed to load recipients');
            console.error("Error fetching data:", err);
          } finally {
            // setLoading(false);
          }
        };
    
        fetchRecipients();
      }, []); // Empty dependency array to run once when the component mounts
      const handleInputChange = (e, id, field) => {
        const updatedAddresses = addresses.map((item) =>
          item.id === id ? { ...item, [field]: e.target.value } : item
        );
        setAddresses(updatedAddresses);
      };
      
    
      const toggleEdit = (id) => {
        const updatedAddresses = addresses.map((item) =>
          item.id === id ? { ...item, isEditing: !item.isEditing } : item
        );
        setAddresses(updatedAddresses);
      };
    
      const handleSave = async (id) => {
        const recipientToUpdate = addresses.find((item) => item.id === id);
      
        try {
          const updatedData = {
            id: recipientToUpdate.id,
            name: recipientToUpdate.fullName,
            relationToPatient: recipientToUpdate.relationship,
            address: recipientToUpdate.address,
            phone: recipientToUpdate.phoneNumber,
          };
      
          const response = await updaterecipients(updatedData);
      
          if (response) {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Recipient updated successfully',
            });
            toggleEdit(id);
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Failed to update recipient',
            });
          }
        } catch (error) {
          console.error('Error updating recipient:', error);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'An error occurred while updating the recipient',
          });
        }
      };
      
    
      const handleDelete = async (id) => {
        Swal.fire({
          title: 'Are you sure?',
          text: 'Do you want to delete this recipient? This action cannot be undone.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Yes, delete it!',
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              const response = await deleteRecipient(id);
      
              if (response && response.message === 'Recipient deleted successfully') {
                Swal.fire({
                  icon: 'success',
                  title: 'Deleted',
                  text: response.message,
                });
                setAddresses(addresses.filter((item) => item.id !== id));
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: 'Failed to delete recipient',
                });
              }
            } catch (error) {
              console.error('Error deleting recipient:', error);
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'An error occurred while deleting the recipient',
              });
            }
          }
        });
      };
      
      
      
      
  return (
    <Container className="mt-5">
    <div className="address-book-container">
    <h1 className='text-center mainhead  fw-bold '>Recipient Profile</h1>
      {loader ?(<>
        <LoadingSpinner/>
        
      </>):(<>
        <Table bordered responsive className="mt-3">
   <thead >
     <tr >
       <th>S#</th>
       <th>Full Name</th>
       <th>RelationShip</th>
       <th>Address</th>
       <th className="text-nowrap">Phone Number</th>
       <th>Action</th>
     </tr>
   </thead>
   <tbody>
     {addresses.map((item, index) => (
       <tr key={item.id}>
         <td>{index + 1}</td>
         <td>
           {item.isEditing ? (
             <Form.Control
               type="text"
               placeholder=""
               value={item.fullName}
               onChange={(e) => handleInputChange(e, item.id, "fullName")}
             />
           ) : (
             item.fullName
           )}
         </td>
         <td>
           {item.isEditing ? (
             <Form.Control
               type="text"
               placeholder=""
               value={item.relationship}
             
                onChange={(e) => handleInputChange(e, item.id, "relationship")}
               
             />
           ) : (
             item.relationship
           )}
         </td>
         <td>
           {item.isEditing ? (
             <Form.Control
               type="text"
               placeholder=""
               value={item.address}
             
                onChange={(e) => handleInputChange(e, item.id, "address")}
              
             />
           ) : (
             item.address
           )}
         </td>
         <td>
           {item.isEditing ? (
             <Form.Control
               type="text"
               placeholder=""
               value={item.phoneNumber}
               
                onChange={(e) => handleInputChange(e, item.id, "phoneNumber")}
            
             />
           ) : (
             item.phoneNumber
           )}
         </td>
         <td className="action">
           {item.isEditing ? (
             <Button
               variant="primary"
               onClick={() => handleSave(item.id)}
               className="btn-sm me-2 bg-white border-0 svbtn"
             >
               Save
             </Button>
           ) : (
             <Button
               variant="secondary"
               onClick={() => toggleEdit(item.id)}
               className="btn-sm me-2 bg-white border-0 editbtn"
             >
               Edit
             </Button>
           )}
           <Button
             variant="danger"
             onClick={() => handleDelete(item.id)}
             className="btn-sm bg-white border-0 delbtn"
           >
             Delete
           </Button>
         </td>
       </tr>
     ))}
   </tbody>
 </Table>

      </>)}


</div>
   </Container>
  )
}

export default RecipientProfile
import React, { useEffect, useState } from "react";
import { Table, Button, Form, Container } from "react-bootstrap";
import './AddressBook.css'
import { address, deleteAdress, updateAdress } from "../../../../services/CustomerDashboard/address";
import Swal from "sweetalert2";
import LoadingSpinner from "../../../../Components/Spinner/LoadingSpinner";
const AddressBook = () => {
      const [loader,setLoader]=useState(true);
  
  const [addresses, setAddresses] = useState([
   
  ]);

  // const handleAddNew = () => {
  //   const newId = addresses.length + 1;
  //   setAddresses([
  //     ...addresses,
  //     {
  //       id: newId,
  //       address: "",
  //       cityCountry: "",
  //       phoneNumber: "",
  //       isEditing: true,
  //     },
  //   ]);
  // };

  const handleInputChange = (e, id, field) => {
    const updatedAddresses = addresses.map((item) =>
      item.id === id ? { ...item, [field]: e.target.value } : item
    );
    setAddresses(updatedAddresses);
  };

   const handleDelete = async (id) => {
         Swal.fire({
           title: 'Are you sure?',
           text: 'Do you want to delete this Address? This action cannot be undone.',
           icon: 'warning',
           showCancelButton: true,
           confirmButtonColor: '#d33',
           cancelButtonColor: '#3085d6',
           confirmButtonText: 'Yes, delete it!',
         }).then(async (result) => {
           if (result.isConfirmed) {
             try {
               const response = await deleteAdress(id);
       
               if (response && response.message === 'Address deleted successfully') {
                 Swal.fire({
                   icon: 'success',
                   title: 'Deleted',
                   text: response.message,
                 });
                 setAddresses(addresses.filter((item) => item.id !== id));
               } else {
                 Swal.fire({
                   icon: 'error',
                   title: 'Error',
                   text: 'Failed to delete Address',
                 });
               }
             } catch (error) {
               console.error('Error deleting Address:', error);
               Swal.fire({
                 icon: 'error',
                 title: 'Error',
                 text: 'An error occurred while deleting the Address',
               });
             }
           }
         });
       };
       

  const toggleEdit = (id) => {
    const updatedAddresses = addresses.map((item) =>
      item.id === id ? { ...item, isEditing: !item.isEditing } : item
    );
    setAddresses(updatedAddresses);
  };
    useEffect(() => {
        const fetchAddress = async () => {
          try {
            const response = await address();
            // Log the response
            console.log("Response Data:", response.data);
            if (Array.isArray(response.data)) {
              const processedData = response.data.map((item) => ({
                id: item.id,
                city:item.city||"",
                state:item.state||"",
                address: item.address || "No Address",
                cityCountry: item.city|| "No City/Country", // Add this if available
                phoneNumber: item.phone || "No Phone Number",
                isEditing: false,
              }));
              setLoader(false)           
      
              // Set the processed data in the state
              setAddresses(processedData);
            } 
            // Optionally, you can set the data in the state if you need to display it
            // setAddresses(data); // Assuming data is an array of Addresss
          } catch (err) {
            // setError('Failed to load Addresss');
            console.error("Error fetching data:", err);
          } finally {
            // setLoading(false);
          }
        };
    
        fetchAddress();
      }, []); // Empty dependency array to run once when the component mounts

      const handleSave = async (id) => {
        const addressToUpdate = addresses.find((item) => item.id === id);
    
        try {
          const updatedData = {
            id: addressToUpdate.id,
            address: addressToUpdate.address,
            city: addressToUpdate.city,
            state: addressToUpdate.state,
            phone: addressToUpdate.phoneNumber,
          };
    
          const response = await updateAdress(updatedData);
    
          if (response) {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Address updated successfully.",
            });
            toggleEdit(id);
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "Failed to update address.",
            });
          }
        } catch (error) {
          console.error("Error updating address:", error);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "An error occurred while updating the address.",
          });
        }
      };
    


  return (
   
    <Container className="mt-5">
         <div className="address-book-container">
         <h1 className='text-center mainhead  fw-bold '>Address Book</h1>
         {loader ?(<>
        <LoadingSpinner/>
        
      </>):(<>

        <Table bordered responsive className="mt-3">
        <thead >
          <tr >
            <th>S#</th>
            <th>Address</th>
            <th>City</th>
            <th>State</th>
            <th className="text-nowrap">Phone Number</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {addresses.map((item, index) => (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td>
                {item.isEditing ? (
                  <Form.Control
                    type="text"
                    placeholder="Enter Address"
                    value={item.address}
                    onChange={(e) => handleInputChange(e, item.id, "address")}
                  />
                ) : (
                  item.address
                )}
              </td>
              <td>
                {item.isEditing ? (
                  <Form.Control
                    type="text"
                    placeholder="Enter City / Country"
                    value={item.city}
                    onChange={(e) =>
                      handleInputChange(e, item.id, "city")
                    }
                  />
                ) : (
                 item.city

                )}
              </td>
              <td>
                {item.isEditing ? (
                  <Form.Control
                    type="text"
                    placeholder="state"
                    value={item.state}
                    onChange={(e) =>
                      handleInputChange(e, item.id, "state")
                    }
                  />
                ) : (
                 item.state

                )}
              </td>
              <td>
                {item.isEditing ? (
                  <Form.Control
                    type="text"
                    placeholder="Enter Phone Number"
                    value={item.phoneNumber}
                    onChange={(e) =>
                      handleInputChange(e, item.id, "phoneNumber")
                    }
                  />
                ) : (
                  item.phoneNumber
                )}
              </td>
              <td className="action">
                {item.isEditing ? (
                  <Button
                    variant="primary"
                    onClick={() =>  handleSave(item.id)}
                    className="btn-sm me-2 bg-white border-0 svbtn"
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    variant="secondary"
                    onClick={() => toggleEdit(item.id)}
                    className="btn-sm me-2 bg-white border-0 editbtn"
                  >
                    Edit
                  </Button>
                )}
                <Button
                  variant="danger"
                  onClick={() => handleDelete(item.id)}
                  className="btn-sm bg-white border-0 delbtn"
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      </>)}
     
    </div>
        </Container>
  );
};

export default AddressBook;

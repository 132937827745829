import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from '../Components/Footer';
import vector from '../assets/Vector.png';
import CustomNavbar from '../Components/Navbar/CustomNavbar';

import images from '../assets/Images'

export default function DeliveryPolicy() {


  return (
    <>
      <CustomNavbar />
      {/* PRIVACY POLICY-------------------------------- */}
      <Container fluid className="position-relative p-0 align-content-center">
        {/* Image Section */}
        <Row className="m-0">
          <Col xs={12} className="p-0">
            <Image
              src={images.Delivery}
              alt="Terms and Conditions"
              fluid
              style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
            />
          </Col>
        </Row>

        {/* Text Section */}
        <Row
          className="position-absolute start-0 ms-1 mt-5 "
          style={{ top: '20%' }}
        >
          <Col xs={12} md={6}>
            <div
              style={{
                color: " white",
                borderLeft: "5px solid #00909D",
                // paddingLeft: '15px',
              }}
              className=" ps-2"
            >
             
            </div>
          </Col>
        </Row>
      </Container>
   <Container fluid className='ps-4 overflow-hidden' >
      {/* Overview Section */}
      <Container fluid>
        <Row className="mx-3 mt-4">
          <Col xs={12}>

            <p className="fs-3 pt-2 ">
              At Smart Medics, we are committed to providing timely and reliable delivery services to our customers. We offer two levels of delivery service to cater to your needs, whether you require pre-sorted medication or regular medication/non-sorted medication. Our goal is to ensure that your medications are delivered promptly and securely.
            </p>
          </Col>
        </Row>
      </Container>

      {/* Delivery Services Section */}
      <Container fluid>
        <Row className="mx-3 mt-4">
          <Col xs={12}>
            <h2 style={{ color: "#00909D" }}>Delivery Services</h2>
          </Col>
          <Col xs={12} className="mt-4">
            <div className="d-flex align-items-start mb-2">
              <img src={vector} alt="icon" className="me-2" style={{ width: '20px', height: '20px' }} />
              <h5 className="fw-bold">Pre-Sorted Medication:</h5>
            </div>
            <ul className="list-unstyled">
              <li className="pt-2">
                <span className="fw-bold">Delivery Time:</span> Within 24 hours.
              </li>
              <li className="pt-2">
              <span className="fw-bold ">Delivery Time Outside the Karachi in 3-5 days business days.</span>

              </li>
              <li className="pt-2">
                <span className="fw-bold">Service Description:</span> Pre-sorted medications are carefully organized and packaged for your convenience. They are delivered straight to your door within 24 hours of placing your order.
              </li>
            </ul>
          </Col>

          <Col xs={12} className="mt-4">
            <div className="d-flex align-items-start mb-2">
              <img src={vector} alt="icon" className="me-2" style={{ width: '20px', height: '20px' }} />
              <h5 className="fw-bold">Regular Medication (Non-Sorted):</h5>
            </div>
            <ul className="list-unstyled">
              <li className="pt-2">
                <span className="fw-bold">Delivery Time:</span> Within 1-2 hours.
              </li>
              <li className="pt-2">
              <span className="fw-bold ">Delivery Time Outside the Karachi in 3-5 days business days.</span>

              </li>
              <li className="pt-2">
                <span className="fw-bold">Service Description:</span> Regular medications are delivered promptly within 1-2 hours of placing your order. This service ensures that you receive your medications as quickly as possible.
              </li>

            </ul>
          </Col>
        </Row>
      </Container>

      {/* Delivery Areas Section */}
      <Container fluid>
        <Row className="mx-3 mt-4">
          <Col xs={12}>
            <h2 style={{ color: "#00909D" }}>Delivery Areas</h2>
            <ul className="list-unstyled">
              <li className="pt-2">
                <span className="fw-bold">Service Area:</span> Currently, our delivery service is available only within Karachi.
              </li>
            </ul>
          </Col>
        </Row>
      </Container>

      {/* Delivery Fees Section */}
      <Container fluid>
        <Row className="mx-3 mt-4">
          <Col xs={12}>
            <h2 style={{ color: "#00909D" }}>Delivery Fees</h2>
            <ul className="list-styled">
              <li className="pt-2">
              <div className='fw-bold fs-5'>
              Orders within Karachi:
              </div>
              <div>
              <span className="fw-bold ps-md-4 ">Order Value below Rs. 3000:</span> Rs. 200 delivery fees.
              </div>
                <div>
              <span className="fw-bold ps-md-4">Order Value Rs. 3000 and above:</span> Free delivery.
              </div>
              
              </li>
              <li className="pt-3">
              <div className='fw-bold fs-5'>
              Orders outside Karachi:
              </div>
              <div >
              <span className="fw-bold ps-md-4 ">Order Weight below 0.5 kg:</span> Rs. 250 delivery fees.
              </div>
                <div>
              <span className="fw-bold ps-md-4">Order Weight 0.5 kg and above:</span>Rs. 250 delivery fees plus Rs. 270 for each additional kilogram over 0.5 kg.
              </div>
              
              </li>
            </ul>
          </Col>
        </Row>
        </Container>
             {/* Delivery Areas Section */}
      <Container fluid>
        <Row className="mx-3 mt-4">
          <Col xs={12}>
            <h2 style={{ color: "#00909D" }}>Unsuccessful Deliveries</h2>
            <ul className="list-unstyled">
              <li className="pt-2">
              If our delivery team is unable to deliver your order due to an incorrect address or unavailability, additional delivery charges may apply for redelivery.
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row className="mx-3 mt-4">
          <Col xs={12}>
            <h2 style={{ color: "#00909D" }}>Additional Information</h2>
            <ul className="list-unstyled">
              <li className="pt-2">
                <span className="fw-bold">Order Tracking:</span> Once your order is placed, you will receive a confirmation email with tracking details. You can monitor the status of your delivery in real-time.
              </li>
              <li className="pt-2">
                <span className="fw-bold">Delivery Hours:</span> Our delivery services operate from 10 AM to 6 PM, seven days a week. Orders placed outside these hours will be processed the next business day.
              </li>
              <li className="pt-2">
                <span className="fw-bold">Contactless Delivery:</span> In light of health and safety concerns, we offer contactless delivery options. Our delivery personnel will leave your package at your doorstep and notify you upon arrival.
              </li>
              <li className="pt-2">
                <span className="fw-bold">Customer Support:</span> For any inquiries or issues related to your delivery, please contact our customer support team at 123-456-689 or email us at contact@smartmedics.com.pk.
              </li>
            </ul>
          </Col>
        </Row>
      </Container>

      {/* Cancellation Section */}
      <Container fluid>
        <Row className="mx-3 mt-4">
          <Col xs={12}>
            <h2 style={{ color: "#00909D" }}>Cancellation</h2>
            <ul className="list-unstyled">
              <li className="pt-2">
                <span className="fw-bold">Cancellation:</span> You must contact our customer service support within 2 hours after the order has been placed. Once the order is dispatched, you can’t cancel it.
              </li>
            </ul>
          </Col>
        </Row>
      </Container>

      {/* Terms and Conditions Section */}
      <Container fluid>
        <Row className="mx-3 mt-4">
          <Col xs={12}>
            <h2 style={{ color: "#00909D" }}>Terms and Conditions</h2>
            <ul className="list-styled">
              <li className="pt-2">Smart Medics reserves the right to modify delivery times and fees based on operational requirements and market conditions.</li>
              <li className="pt-2">Delivery fees are non-refundable once the order has been dispatched.</li>
              <li className="pt-2">Smart Medics is not responsible for any delays caused by unforeseen circumstances such as extreme weather conditions, natural disasters, or other events beyond our control.</li>
            </ul>
            <p className='ps-4'>By using Smart Medics' delivery services, you agree to adhere to the terms and conditions outlined in this policy. Thank you for choosing Smart Medics for your medication needs.</p>
          </Col>
        </Row>
      </Container>
      </Container>
      <Footer />
    </>
  );
}

import React, { useState } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { FaTachometerAlt, FaHome, FaUser, FaShoppingCart, FaFileMedical, FaBell, FaCreditCard, FaHeadset, FaSignOutAlt, FaUserCircle, FaRegUser, FaIdCard, FaAngleDoubleRight, FaAngleDoubleLeft } from 'react-icons/fa';
import './Dashboard.css';
import logo from '../../../../assets/logo.png';
import { Link } from 'react-router-dom';
import NavbarDashboard from '../NavbarDashboard/Navbar';
import { useDispatch } from 'react-redux';
import { clearEmail } from '../../../../features/email/emailSlice';
import { persistor } from '../../../../app/store';



const Dashboard = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const dispatch = useDispatch();
  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };
  const handleItemClick = () => {
    if (window.innerWidth <= 768) {
      setIsCollapsed(true);
    }
  };
  const logout=()=>{
  dispatch(clearEmail())
  sessionStorage.removeItem("isOtpVerified")
// Redirect and reload the page
window.location.href = '/';
  }
  return (
    <>
      <div className="dashboard-container">
        {/* Sidebar */}
        <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
          <div className="d-flex justify-content-center mt-3">
            <Link to={"/"}>
              <img style={{ width: 70 }} src={logo} alt="logo" />
            </Link>
          </div>

          {/* Edge-like Toggle Button */}
          <button className="toggle-btn" onClick={toggleSidebar}>
            {isCollapsed ? <FaAngleDoubleRight/> : <FaAngleDoubleLeft/>}
          </button>

          <ul className="sidebar-list">
            <li className="mt-5">
              <NavLink to="customer-dashboard" className="sidebar-item"   onClick={handleItemClick}>
                <FaTachometerAlt className="sidebar-icon fs-4 me-2" />
                {!isCollapsed && <span className='side-bat-text'>Dashboard</span>}
              </NavLink>
            </li>
            <li className="pt-2">
              <NavLink to="order-management" className="sidebar-item"   onClick={handleItemClick}>
                <FaShoppingCart className="sidebar-icon fs-4 me-2" />
                {!isCollapsed && <span className='side-bat-text'>Order Management</span>}
              </NavLink>
            </li>
            <li className="pt-2">
              <NavLink to="prescription-management" className="sidebar-item"   onClick={handleItemClick}>
                <FaFileMedical className="sidebar-icon fs-4 me-2" />
                {!isCollapsed && <span className='side-bat-text'>Prescription Management</span>}
              </NavLink>
            </li>
            <li className="pt-2">
              <NavLink to="notifications" className="sidebar-item"   onClick={handleItemClick}>
                <FaBell className="sidebar-icon fs-4 me-2" />
                {!isCollapsed && <span className='side-bat-text'>Notifications</span>}
              </NavLink>
            </li>
            <li className="pt-2">
              <NavLink to="recipient-profile" className="sidebar-item"   onClick={handleItemClick}>
                <FaIdCard  className="sidebar-icon fs-4 me-2" />
                {!isCollapsed && <span className='side-bat-text'>Recipient Profile</span>}
              </NavLink>
            </li>
            <li className="pt-2">
              <NavLink to="profile-managment" className="sidebar-item"   onClick={handleItemClick}>
                <FaUser className="sidebar-icon fs-4 me-2" />
                {!isCollapsed && <span className='side-bat-text'>Profile Management</span>}
              </NavLink>
            </li>
            <li className="pt-2">
              <NavLink to="address-book" className="sidebar-item"   onClick={handleItemClick}>
                <FaHome className="sidebar-icon fs-4 me-2" />
                {!isCollapsed && <span className='side-bat-text'>Address Book</span>}
              </NavLink>
            </li>
            <li>
              <NavLink to="payment-method" className="sidebar-item"   onClick={handleItemClick}>
                <FaCreditCard className="sidebar-icon fs-4 me-2" />
                {!isCollapsed && <span className='side-bat-text'>Payment Method</span>}
              </NavLink>
            </li>
            <li className="pt-2">
              <NavLink to="customer-support" className="sidebar-item"   onClick={handleItemClick}>
                <FaHeadset className="sidebar-icon fs-4 me-2" />
                {!isCollapsed && <span className='side-bat-text'>Customer Support</span>}
              </NavLink>
            </li>

            <li>
              <div className="d-flex justify-content-center">
                <hr className="w-75" />
              </div>
            </li>
            <li>
              <NavLink to="sign-out" className="sidebar-item"   onClick={logout}>
                <FaUserCircle className="sidebar-icon fs-4 me-2" />
                {!isCollapsed && <span className='side-bat-text'>Sign Out</span>}
              </NavLink>
            </li>
          </ul>
        </div>

        {/* Content */}
        <div className="content ">
          {/* Custom Navbar */}
          <NavbarDashboard/>
          {/* Main Content */}
          <div className="main-content">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;


import React, { useEffect, useState } from 'react';
import { Container,Form, Row, Col, Button } from 'react-bootstrap';
import './ProfileManagement.css';
import { getCustomerData, UserProfile } from '../../../../services/CustomerDashboard/profileManagement';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { setProfileData } from '../../../../features/profile/profileSlice';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const ProfileManagement = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName:"",
    address: "",
    primaryEmail: "",
    secondaryEmail: "",
    primaryPhone: "",
    secondaryPhone: "",
  });
  const [isPrimaryPhoneDisabled, setIsPrimaryPhoneDisabled] = useState(false);
  const [isPrimaryEmailDisabled, setIsPrimaryEmailDisabled] = useState(false);

  const [errors, setErrors] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCustomerData(); // Fetch customer data using the POST method
        dispatch(setProfileData(response.data)); // Update Redux state

        // Check and log the full response structure
        console.log("Full Response:", response);
  
        if (response?.data) {
          const customerData = response.data; // Extract the correct data object
          console.log(response.data.primaryPhone);

          // check signup method 
         if(response.data.signup_method==="email"){
         setIsPrimaryEmailDisabled(customerData.primaryEmail !== null);
                
         }
          console.log("Customer Data:", customerData);
  
          // Update formData state with the fetched customer data
          setFormData({
            firstName: customerData.firstName || "",
            lastName: customerData.lastName || "",
            address: customerData.address || "",
            primaryEmail: customerData.primaryEmail || "",
            secondaryEmail: customerData.secondaryEmail || "",
            primaryPhone: customerData.primaryPhone || "",
            secondaryPhone: customerData.secondaryPhone || "",
          });
        //      // Determine whether the primaryPhone input should be disabled
        //  setIsPrimaryPhoneDisabled(customerData.primaryPhone !== null);

        } else {
          console.error("Customer data structure is not as expected:", response);
        }
      } catch (error) {
        console.error("Error fetching customer data:", error);
      }
    };
  
    fetchData();
  }, [dispatch]);
  
  
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handlePhoneChange = (value, name) => {
    setFormData({ ...formData, [name]: value });
  };
  const validate = () => {
    const newErrors = {};

    // if (!formData.fullName.trim()) {
    //   newErrors.fullName = "Full Name is required.";
    // }

    if (!formData.address.trim()) {
      newErrors.address = "Address is required.";
    }

    if (!formData.primaryEmail || !/\S+@\S+\.\S+/.test(formData.primaryEmail)) {
      newErrors.primaryEmail = "Valid Primary Email is required.";
    }

    if (formData.secondaryEmail && !/\S+@\S+\.\S+/.test(formData.secondaryEmail)) {
      newErrors.secondaryEmail = "Valid Secondary Email is required.";
    }

    if (!formData.primaryPhone) {
      newErrors.primaryPhone = "Primary Phone Number is required.";
    }

    if (!formData.secondaryPhone) {
      newErrors.primaryPhone = "Secondary Phone Number is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        const response = await UserProfile(formData); // Pass the form data
         Swal.fire({
                 title: "Profile Updated!",
                 text: "You Profle Updated Successfully.",
                 icon: "success",
                 confirmButtonColor: "#00909D",
                 
               }).then(() => {
                // Reload the page
                window.location.reload();
              });
        
        console.log('API Response:', response);

        // console.log(formData);
        
      } catch (error) {
        console.error("Error submitting profile:", error);
        
        // Extract error message from response
        const validationErrors =  error.response?.data?.errors || {};
        // The Object.values() method takes an object and returns an array of its values (ignoring the keys).
        const errorMessages = Object.values(validationErrors).join("\n");
        Swal.fire({
          title: "Error",
          text: errorMessages,
          icon: "error",
          confirmButtonColor: "#d33",
        });
      }
    }
  };
  
  return (
    <Container className='mt-5'>
        <h1 className='text-center mainhead  fw-bold '>Profile Management</h1>
        <Form onSubmit={handleSubmit}>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={12} md={4}>
            First Name
          </Form.Label>
          <Col sm={12} md={12}>
            <Form.Control
              type="text"
              placeholder="FirstName"
              name="firstName"
      className="custom-input-bg "
              value={formData.firstName}
              onChange={handleChange}
              // isInvalid={!!errors.fullName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.fullName}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={12} md={4}>
         Last Name
          </Form.Label>
          <Col sm={12} md={12}>
            <Form.Control
              type="text"
              placeholder="LastName"
              name="lastName"
      className="custom-input-bg "
              value={formData.lastName}
              onChange={handleChange}
              // isInvalid={!!errors.fullName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.fullName}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={12} md={12}>
            Address
          </Form.Label>
          <Col sm={12} md={12}>
            <Form.Control
              type="text"
              placeholder="Your Address"
              name="address"
              value={formData.address}
      className="custom-input-bg "

              onChange={handleChange}
              isInvalid={!!errors.address}
            />
            <Form.Control.Feedback type="invalid">
              {errors.address}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>Primary Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email Address"
      className="custom-input-bg "
      disabled={isPrimaryEmailDisabled}
                name="primaryEmail"
                value={formData.primaryEmail}
                onChange={handleChange}
                isInvalid={!!errors.primaryEmail}
              />
              <Form.Control.Feedback type="invalid">
                {errors.primaryEmail}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Secondary Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email Address"
      className="custom-input-bg "

                name="secondaryEmail"
                value={formData.secondaryEmail}
                onChange={handleChange}
                isInvalid={!!errors.secondaryEmail}
              />
              <Form.Control.Feedback type="invalid">
                {errors.secondaryEmail}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>Primary Phone Number</Form.Label>
              <PhoneInput
                country={"pk"}
                value={formData.primaryPhone}
                onlyCountries={['pk']} // Restrict dropdown to only Pakistan
                onChange={(value) => handlePhoneChange(value, "primaryPhone")}
                inputProps={{
                  name: "primaryPhone",
                  disabled: isPrimaryPhoneDisabled,
                }}
                enableSearch={false} // Disable the search box as there's only one country
                inputStyle={{ width: "100%" }}
              />
              {errors.primaryPhone && (
                <div className="text-danger mt-1">{errors.primaryPhone}</div>
              )}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Secondary Phone Number</Form.Label>
              <PhoneInput
                country={"pk"}
                value={formData.secondaryPhone}
                onChange={(value) => handlePhoneChange(value, "secondaryPhone")}
                inputProps={{
                  name: "secondaryPhone",
                }}
                 enableSearch={false} // Disable the search box as there's only one country
                onlyCountries={['pk']} // Restrict dropdown to only Pakistan
                inputStyle={{ width: "100%" }}
              />
              {errors.secondaryPhone && (
                <div className="text-danger mt-1">{errors.secondaryPhone}</div>
              )}
            </Form.Group>
          </Col>
        </Row>

        <div className="d-flex flex-column align-items-center flex-md-row   justify-content-md-end justify-content-center">
        
          <Button variant="primary" type="submit" className='savebtn fw-bolder fs-5  '>
            SAVE
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default ProfileManagement;

import React, { useEffect, useRef, useState } from 'react';
import { Table, Container, Form, Button } from 'react-bootstrap';
import './Notifications.css'
import { emailNotifications, fetchNotificaitions, NotificationToggle, smsNotifications } from '../../../../services/CustomerDashboard/NotificationsToggle';
import LoadingSpinner from '../../../../Components/Spinner/LoadingSpinner';
import { toast } from 'react-toastify';

const Notifications = () => {
  
  const rowRefs =useRef([]);
  const [notifications, setNotifications] = useState([]);
  
      const [loader,setLoader]=useState(true);
  
  // Toggle state for SMS and Email notifications
  const [smsEnabled, setSmsEnabled] = useState(false);
  const [emailEnabled, setEmailEnabled] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 10;
  const handleEmailToggle = async () => {
    const newValue = !emailEnabled;
    setEmailEnabled(newValue); // Update local state
    console.log(newValue);
    if (newValue) {
      toast.success(`Email notifications enabled`, {
        position: "bottom-left",
        autoClose: 500, // Toast will disappear after 1 second
        hideProgressBar: true, // Hides the progress bar
      });
    } else {
      toast.info(`Email notifications disabled`, {
        position: "bottom-left",
        autoClose: 500, // Toast will disappear after 1 second
        hideProgressBar: true, // Hides the progress bar
      });
    }  console.log(newValue);
    try {
      await emailNotifications(newValue); // Send the updated value to the API
    } catch (error) {
      console.error('Failed to update email notifications:', error);
    }
  };

  const handlesmsToggle = async () => {

    const newValue = !smsEnabled;
    setSmsEnabled(newValue); // Update local state
    if (newValue) {
      toast.success(`SMS notifications enabled`, {
        position: "bottom-left",
        autoClose: 500, // Toast will disappear after 1 second
        hideProgressBar: true, // Hides the progress bar
      });
    } else {
      toast.info(`SMS notifications disabled`, {
        position: "bottom-left",
        autoClose: 500, // Toast will disappear after 1 second
        hideProgressBar: true, // Hides the progress bar
      });
    }  console.log(newValue);
     
    try {
      await smsNotifications(newValue); // Send the updated value to the API
    } catch (error) {
      console.error('Failed to update sms notifications:', error);
    }
  };

  // function to call toggle behaviour from backend 
  useEffect(() => {
    // Function to fetch and log data
    const fetchData = async () => {
      try {
        const data = await NotificationToggle(); // Call the API function
        setLoader(false)           

        console.log(data.sms_notif);
        console.log(data.email_notif);
        setSmsEnabled(data.sms_notif === 1); // Convert API response to boolean
        
        setEmailEnabled(data.email_notif === 1);
       
      } catch (error) {
        console.error('Error fetching customer data:', error.message); // Log any errors
      }
    };

    fetchData(); // Execute the fetch function
  }, []); // Empty dependency array ensures this runs once when the component mounts
  //function to call notifications 
 
    const fetchData = async (page) => {
      try {
        const { data, last_page } = await fetchNotificaitions(page, itemsPerPage); // Include customerId
        setNotifications(data); // Update state with fetched notifications
        setTotalPages(last_page); // Update total pages for pagination
    } catch (error) {
      console.error('Error fetching notifications:', error.message);
    }
    };

 
  // Fetch data whenever the page changes
  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  // Pagination controls
  const handlePrevious = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };
  return (
    <>  
    <Container className='mt-5'>
      <h1 className='text-center mainhead  fw-bold '>Notifications</h1>
      {/* Notification Table */}
      {loader ?(<>
        <LoadingSpinner/>
        
      </>):(<>
        <Table  bordered hover responsive className='mt-4 notif_table'>
        <thead >
          <tr >
          <th></th>
            <th>Date</th>
            <th>Time</th>
            <th>Description</th>
            <th className='text-nowrap'>View Details</th>
          </tr>
        </thead>
        <tbody >
        {notifications.length > 0 ?(<>

          {notifications.map((item, index) => (
            <tr   ref={(el) => (rowRefs.current[index] = el)} // Assign unique ref
              className={item.notification_read === 1 ? 'bg-danger' : 'fw-bold'}  key={index}>
              <td>
                <Form.Group controlId="formSaveAddress" className="mt-3">
                                        <Form.Check
                                          type="checkbox"
                                         className=''
                
                                        />
                                      </Form.Group>
                
              </td>
              <td className='text-nowrap p-4'>{item.date}</td>
              <td  className='text-nowrap p-4'>{item.time}</td>
              <td className='p-4'>{item.description}</td>
              <td className='pt-4'>
                <a href={item.link} className='text-decoration-none text-black'>
                  View 
                </a>
              </td>
            </tr>
          ))}
        </>):(<>
          <tr>
                             <td colSpan="5" className="text-center">
                               No New Notification
                             </td>
                           </tr>
        </>)}
         
        </tbody>
      </Table>
      <div className="text-center mt-4">
          <Button className="custom-button"  style={{
      backgroundColor: currentPage === 1 ? 'gray' : '#008F9D',
      color: 'white',
      cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
    }} onClick={handlePrevious} disabled={currentPage === 1}>
            Previous
          </Button>
          <span>
            {' '}Page {currentPage} of {totalPages}{' '}
          </span>
          <Button className="custom-button " style={{
      backgroundColor: currentPage === totalPages ? 'gray' : '#008F9D',
      color: 'white',
      cursor: currentPage === totalPages ? 'not-allowed' : 'pointer',
    }} onClick={handleNext} disabled={currentPage === totalPages}>
            Next
          </Button>
        </div>
      </>)}
    
      {/* Toggle Switches */}
      <div className="mt-5">
        <Form>
          <Form.Check
            type="switch"
            id="sms-notification-switch"
            label="Enable SMS notifications"
            checked={smsEnabled}
            
            onChange={handlesmsToggle}
          />
          <Form.Check
            type="switch"
            id="email-notification-switch"
            label="Enable Email notifications"
            checked={emailEnabled}

            onChange={handleEmailToggle} // Call the handler on toggle
          />
        </Form>
       
      </div>
    </Container>
    </>
  
  );
};

export default Notifications;

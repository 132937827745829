
import React, { useEffect, useState } from 'react';
import { Container,Form, Row, Col, Button } from 'react-bootstrap';
import './ProfileManagement.css';
import { getCustomerData, UserProfile } from '../../../../services/CustomerDashboard/profileManagement';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { setProfileData } from '../../../../features/profile/profileSlice';
import PhoneInput from "react-phone-input-2";
import InputMask from "react-input-mask";
import "react-phone-input-2/lib/style.css";
const ProfileManagement = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName:"",
    address: "",
    primaryEmail: "",
    secondaryEmail: "",
    primaryPhone: "",
    secondaryPhone: "",
  });
  const [isPrimaryPhoneDisabled, setIsPrimaryPhoneDisabled] = useState(false);
  const [isPrimaryEmailDisabled, setIsPrimaryEmailDisabled] = useState(false);
  const [isEditing, setIsEditing] = useState(false); // Track editing mode
  const [errors, setErrors] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCustomerData(); // Fetch customer data using the POST method
        dispatch(setProfileData(response.data)); // Update Redux state

        // Check and log the full response structure
        console.log("Full Response:", response);
  
        if (response?.data) {
          const customerData = response.data; // Extract the correct data object
          console.log(response.data.primaryPhone);

          // check signup method 
         if(response.data.signup_method==="email"){
         setIsPrimaryEmailDisabled(customerData.primaryEmail !== null);
                
         }
          console.log("Customer Data:", customerData);
  
          // Update formData state with the fetched customer data
          setFormData({
            firstName: customerData.firstName || "",
            lastName: customerData.lastName || "",
            address: customerData.address || "",
            primaryEmail: customerData.primaryEmail || "",
            secondaryEmail: customerData.secondaryEmail || "",
            primaryPhone: customerData.primaryPhone || "",
            secondaryPhone: customerData.secondaryPhone || "",
          });
        //      // Determine whether the primaryPhone input should be disabled
         setIsPrimaryPhoneDisabled(customerData.primaryPhone !== null);

        } else {
          console.error("Customer data structure is not as expected:", response);
        }
      } catch (error) {
        console.error("Error fetching customer data:", error);
      }
    };
  
    fetchData();
  }, [dispatch]);
  
  
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handlePhoneChange = (value, name) => {
    setFormData({ ...formData, [name]: value });
  };
  const validate = () => {
    const newErrors = {};
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; // Email regex
    const phoneRegex = /^[0-9]{4}-[0-9]{7}$/; // Regex for phone format: XXXX-XXXXXXX
    // if (!formData.fullName.trim()) {
    //   newErrors.fullName = "Full Name is required.";
    // }

    if (!formData.address.trim()) {
      newErrors.address = "Address is required.";
    }

    if (!formData.primaryEmail || !emailRegex.test(formData.primaryEmail)) {
      newErrors.primaryEmail = "Valid Primary Email is required.";
    }
  
    if (formData.secondaryEmail && !emailRegex.test(formData.secondaryEmail)) {
      newErrors.secondaryEmail = "Valid Secondary Email is required.";
    }

    if (!formData.primaryPhone) {
      newErrors.primaryPhone = "Primary Phone Number is required.";
    }

    if (!formData.secondaryPhone) {
      newErrors.primaryPhone = "Secondary Phone Number is required.";
    }
    // if (!formData.primaryPhone || !phoneRegex.test(formData.primaryPhone)) {
    //   newErrors.primaryPhone = "Phone number must be in the format XXXX-XXXXXXX.";
    // }
    if (!formData.secondaryPhone || !phoneRegex.test(formData.secondaryPhone)) {
      newErrors.secondaryPhone = "Phone number must be in the format XXXX-XXXXXXX.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to save the changes?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, save it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // User confirmed to save changes
        if (validate()) {
          try {
            const response = await UserProfile(formData); // Pass the form data
            Swal.fire({
              title: "Profile Updated!",
              text: "Your profile has been updated successfully.",
              icon: "success",
              confirmButtonColor: "#00909D",
            }).then(() => {
               // Reload the page
               window.location.reload();
              // Optionally reload the page or perform any additional actions
              setIsEditing(false); // Exit edit mode
            });
          } catch (error) {
            console.error("Error submitting profile:", error);
            const validationErrors = error.response?.data?.errors || {};
            const errorMessages = Object.values(validationErrors).join("\n");
            Swal.fire({
              title: "Error",
              text: errorMessages,
              icon: "error",
              confirmButtonColor: "#d33",
            });
          }
        }
      } else {
        // User canceled, no further action
        Swal.fire({
          title: "Cancelled",
          text: "Your changes were not saved.",
          icon: "info",
          confirmButtonColor: "#00909D",
        });
      }
    });
  };
  const handleEdit = () => {
    setIsEditing(true);
  };

  
  return (
    <Container className='mt-5'>
        <h1 className='text-center mainhead  fw-bold '>Profile Management</h1>
        <Form onSubmit={handleSubmit}>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={12} md={4}>
            First Name
          </Form.Label>
          <Col sm={12} md={12}>
            <Form.Control
              type="text"
              placeholder="FirstName"
              name="firstName"
      className="custom-input-bg "
              value={formData.firstName}
              disabled={!isEditing}
              onChange={handleChange}
              // isInvalid={!!errors.fullName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.fullName}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={12} md={4}>
         Last Name
          </Form.Label>
          <Col sm={12} md={12}>
            <Form.Control
              type="text"
              placeholder="LastName"
              name="lastName"
      className="custom-input-bg "
      disabled={!isEditing}
              value={formData.lastName}
              onChange={handleChange}
              // isInvalid={!!errors.fullName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.fullName}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={12} md={12}>
            Address
          </Form.Label>
          <Col sm={12} md={12}>
            <Form.Control
              type="text"
              placeholder="Your Address"
              name="address"
              value={formData.address}
              disabled={!isEditing}
      className="custom-input-bg "

              onChange={handleChange}
              isInvalid={!!errors.address}
            />
            <Form.Control.Feedback type="invalid">
              {errors.address}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>Primary Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email Address"
      className="custom-input-bg "
      disabled={isPrimaryEmailDisabled}
                name="primaryEmail"
                value={formData.primaryEmail}
                onChange={handleChange}
                
                isInvalid={!!errors.primaryEmail}
              />
              <Form.Control.Feedback type="invalid">
                {errors.primaryEmail}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Secondary Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email Address"
      className="custom-input-bg "
              disabled={!isEditing}

                name="secondaryEmail"
                value={formData.secondaryEmail}
                onChange={handleChange}
                isInvalid={!!errors.secondaryEmail}
              />
              <Form.Control.Feedback type="invalid">
                {errors.secondaryEmail}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
          <Form.Group>
              <Form.Label>Primary Phone Number</Form.Label>
              <InputMask
      className="custom-input-bg "
              
                mask="9999-9999999" // Pakistan phone number format
                value={formData.primaryPhone}
                disabled={isPrimaryPhoneDisabled}
                 
                onChange={(e) => setFormData({ ...formData, primaryPhone: e.target.value })}
              >
                {(inputProps) => (
                  <Form.Control
                    {...inputProps}
                    type="text"
                    placeholder="Primary Phone"
                    name="primaryPhone"
                    isInvalid={!!errors.primaryPhone}

                  />
                )}
              </InputMask>
              <Form.Control.Feedback type="invalid">{errors.primaryPhone}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
          
            <Form.Group>

              <Form.Label>Secondary Phone Number</Form.Label>
              <InputMask
      className="custom-input-bg "
      disabled={!isEditing}

                mask="9999-9999999" // Pakistan phone number format
                value={formData.secondaryPhone}
                onChange={(e) => setFormData({ ...formData, secondaryPhone: e.target.value })}
              >
                {(inputProps) => (
                  <Form.Control
                    {...inputProps}
                    type="text"
                    placeholder="Secondary Phone"
                    name="secondaryPhone"
                    isInvalid={!!errors.secondaryPhone}
                  />
                )}
              </InputMask>
              <Form.Control.Feedback type="invalid">{errors.secondaryPhone}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        {/* Action Buttons */}
        <div className="d-flex flex-column align-items-center flex-md-row   justify-content-md-end justify-content-center">
          {isEditing ? (
            <>
              <Button variant="primary" onClick={handleSubmit} className="me-2 savebtn">
                Save Changes
              </Button>
              <Button
                variant="secondary"
                onClick={() => setIsEditing(false)}
              >
                Cancel
              </Button>
            </>
          ) : (
            <Button className='savebtn' variant="primary" onClick={handleEdit}>
              Edit Profile
            </Button>
          )}
        </div>
      </Form>
    </Container>
  );
};

export default ProfileManagement;

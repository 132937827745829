import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import AboutUs from './Screens/AboutUs';
import AllProducts from './Screens/AllProducts';
import ProductDetails from './Screens/ProductDetails';
import CheckOut from './Screens/CheckOut';
import 'react-toastify/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import PrescriptionOrder from './Screens/PreSortedOrder/PrescriptionOrder';
import PrivacyPolicy from './Screens/PrivacyPolicy';
import Conditions from './Screens/Conditions';
import DeliveryPolicy from './Screens/DeliveryPolicy';
import CancelationPolicy from './Screens/CancelationPolicy';
import Home from './Screens/Home';
import TrackOrder from './Screens/TrackOrder';
import OrderConfirmation from './Screens/OrderConfirmation';
import ScrollToTop from './Components/ScrollToTop';

import Verification from './Screens/VerificationScreen/Verification';
import ThankYouScreen from './Screens/ThankYouScreen/ThankYouScreen';
import OrderHistory from './Screens/OrdersHistory/OrderHistory';
import LoadingSpinner from './Components/Spinner/LoadingSpinner';
import Dashboard from './Screens/CustomerDashboard/Components/Dashboard/Dashboard';



import Notifications from './Screens/CustomerDashboard/Components/Notifications/Notifications';
import ProfileManagement from './Screens/CustomerDashboard/Components/ProfileManagement/ProfileManagement';
import AddressBook from './Screens/CustomerDashboard/Components/AddressBook/AddressBook';
import PaymentMethod from './Screens/CustomerDashboard/Components/PaymentMethod/PaymentMethod';
import Default from './Screens/CustomerDashboard/Components/Dashboard/Default/Default';
import PrescriptionManagement from './Screens/CustomerDashboard/Components/PrescriptionManagement/PrescriptionManagement';
import RecipientProfile from './Screens/CustomerDashboard/Components/RecipientProfile/RecipientProfile';
import OrderManagement from './Screens/CustomerDashboard/Components/OrderManagement/OrderManagement';



function App() {
  const [isOtpVerified, setIsOtpVerified] = useState(null); // Default to null until checked

  // Check localStorage for OTP verification state on load
  useEffect(() => {
    const otpVerified = sessionStorage.getItem('isOtpVerified') === 'true';
    setIsOtpVerified(otpVerified);
  }, []);

  // Update localStorage whenever the state changes
  useEffect(() => {
    if (isOtpVerified !== null) { // Only update if the state is set
      sessionStorage.setItem('isOtpVerified', isOtpVerified);
    }
  }, [isOtpVerified]);

  if (isOtpVerified === null) {
    // Render nothing or a loading spinner until OTP verification state is checked
    return <LoadingSpinner />;
  }

  return (
    <Router>
      <ScrollToTop />
      <ToastContainer />
      <Routes>
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/allproducts' element={<AllProducts />} />
        
        {/* Protected Route */}
        <Route 
          path='/preSorted-order' 
          element={isOtpVerified ? <PrescriptionOrder /> : <Navigate to="/verification" />} 
        />
         
        <Route path='/' element={<Home />} />
        <Route path='/productdetails' element={<ProductDetails />} />
        <Route path='/checkout' element={<CheckOut />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='terms-conditions' element={<Conditions />} />
        <Route path='/delivery-policy' element={<DeliveryPolicy />} />
        <Route path='/cancellation-policy' element={<CancelationPolicy />} />
        <Route path='/track-order' element={<TrackOrder />} />
        <Route path="/order-confirmation" element={<OrderConfirmation />} />
        
        {/* Pass setIsOtpVerified to Verification for OTP verification */}
         
         {/* if otp is verified then user direct to pre-sorted form else verifiacation page loads */}
        <Route
  path="/verification"
  element={
    isOtpVerified ? (
      <Navigate to="/preSorted-order" />
    ) : (
      <Verification setIsOtpVerified={setIsOtpVerified} />
    )
  }
/>

        <Route path="/thankyou" element={<ThankYouScreen />} />
        <Route path="/history" element={<OrderHistory />} />
        
        <Route path="/spin" element={<LoadingSpinner />} />
        {/* if any route is not present  */}
        <Route path="*" element={<Navigate replace to="/" />} />

     {/* ------------------------Customer Dashboard-------------------------- */}

  {/* Dashboard route */}
  <Route path="/dashboard" element={
    isOtpVerified ? (
      <Dashboard/>
    ) : (
      <Navigate to="/"  />
    )
  }>
          {/* Default route to load when /dashboard is visited */}
          <Route index element={<Default />} />
          
          {/* Additional routes */}
          <Route path="order-management" element={<OrderManagement />} />
          <Route path="customer-dashboard" element={<Default />} />
          <Route path="prescription-management" element={<PrescriptionManagement />} />
          <Route path="notifications" element={<Notifications />} />
          <Route path="profile-managment" element={<ProfileManagement />} />
          <Route path="address-book" element={<AddressBook />} />
          <Route path="payment-method" element={<PaymentMethod />} />
          <Route path="recipient-profile" element={<RecipientProfile/>} />






          {/* Uncomment and add your other routes */}
          {/* <Route path="prescription-management" element={<PrescriptionManagement />} /> */}
          {/* <Route path="notifications" element={<Notifications />} /> */}
          {/* <Route path="profile-management" element={<ProfileManagement />} /> */}
          {/* <Route path="address-book" element={<AddressBook />} /> */}
          {/* <Route path="payment-method" element={<PaymentMethod />} /> */}
          {/* <Route path="customer-support" element={<CustomerSupport />} /> */}
        </Route>
        
      </Routes>
    </Router>
  );
}

export default App;
